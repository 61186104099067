<template>
    <div>
        <!--AxZInputcyclefgdDetail.vue-->
        <el-container>
            <el-main style="height: 600px;">
                <el-row>
                    <el-col :span="20">
                        <el-button-group>
                            <el-button type="" size="mini" @click="sysdetailadd">新增</el-button>
                            <el-button type="" size="mini" @click="sysdetailupdate">修改</el-button>
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                        </el-button-group>
                    </el-col>
                </el-row>
                <vxe-form title-align="right" title-width="140">
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="类型" field="kind" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].kind" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].kind" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="工艺类型" field="gykind" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].gykind" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].gykind" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="开料" field="kl" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].kl" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].kl" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="组装" field="zuz" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].zuz" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].zuz" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="喷涂" field="pt" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].pt" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].pt" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="质检" field="zj" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].zj" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].zj" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="inputdate" field="inputdate" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].inputdate" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].inputdate" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_z_inputcyclefgddetail",
        extends: ax.control1(),
        data() {
            return {
                vouchertype: "detail",
                mastertable: "ax_z_inputcyclefgd",
                model: "zb",
                settings: "axZInputcyclefgd",
                datainit: {
                    "ax_z_inputcyclefgd": {
                        "keyname": "ax_z_inputcyclefgd",
                        "key": "guid",
                        "ptable": "",
                        "pkey": "",
                        "relation": "",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                               kind:"",
                               gykind:"",
                               kl:"",
                               zuz:"",
                               pt:"",
                               zj:"",
                               inputdate:"",
                        }]
                    }
                }
            }
        },
    }
</script>